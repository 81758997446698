<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7"
          >*温馨提示：请确保以上信息真实有效。若有填错信息，请在该页面信息修改后，及时到窗口收费处进行修改，避免就医时带来困扰</span
        >
      </template>
    </van-cell>
    <van-form @submit="onSubmit">
      <van-field v-model="username" name="用户名" label="姓名" placeholder="请输入姓名" />
      <van-field
        v-model="idCord"
        type="number"
        name="身份证号"
        label="身份证号码"
        placeholder="请输入身份证号"
      />
      <van-field
        v-model="phone"
        type="number"
        name="手机号"
        label="手机号码"
        placeholder="请输入手机号码"
      />
      <!-- <van-field
        v-model="familyAddress"
        label="家庭地址"
        placeholder="请输入家庭地址"
      /> -->
      <van-field readonly v-model="Sex" name="性别" label="性别" placeholder="请输入性别" />
      <van-field
        readonly
        v-model="Birthday"
        type="number"
        label="出生日期"
        name="日期"
        placeholder="请输入出生日期"
      />
      <div style="margin: 16px">
        <van-button round block type="info" color="rgb(87, 196, 183)" native-type="submit"
          >提交修改</van-button
        >
      </div>
    </van-form>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import "@/assets/iconfont/write/iconfont.css";
import ajax from "../../lib/ajax";
import store from "../../store";
import { Toast } from "vant";
export default {
  data() {
    return {
      username: "",
      idCord: "",
      phone: "",
      familyAddress: "",
      Sex: "",
      Birthday: "",
      Birthdays:"",//就诊卡日期格式
      uid:"",

      // userId: 0,
      // show: false,
      // logoSrc: "",
       list: {},
      // showDialog: false,
      // value: "",
      // id: 0,
      // key: "",
      // label: "",
      qrCodeText: "",
      wechatCode: "",
    };
  },
  created() {
    let wechatCode = this.$route.query.wechatCode;
    // let wechatCode = `C1D5E2A7D321AC3103ED30E021839008`;
     let redirect_uri="http://wx.fgxrmyy.cn/uinfo";
    if (!wechatCode) {
      window.location.href = `https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirect_uri}`;
    }
    this.wechatCode = wechatCode;
    this.onload();
  },
  methods: {
    async onload() {
      // let lists = [];
      // let openid = store.getters["wechat/openid"];
       let openid =  sessionStorage.getItem('openid');
      //  let uinfo =  sessionStorage.getItem('uinfo');
      // this.userId = store.getters["store/userId"];
      console.log("sss")
      // console.log(uinfo)
      this.qrCodeText =  sessionStorage.getItem('upqrCodeText');
      let idcard = sessionStorage.getItem('upidCard');
      await ajax
        .post("/Api/CardService/WeChatGetOwnerMembers?input=" + openid)
        .then((res) => {
          this.list = [];
          let uData = res.data.result;
          for (let i = 0; i < uData.length; i++) {
            if (uData[i].idCard === idcard) {
              // this.list.push(uData[i]);
              console.log("keje")
              console.log(uData[i])
              let c = uData[i].idCard;
              let year = c.substring(6, 10);
              let month = c.substring(10, 12);
              let day = c.substring(12, 14);
              let sex = c.substring(16, 17);
              this.Sex = sex % 2 > 0 ? "男" : "女";
              this.Birthday = year + "/" + month + "/" + day;
             //页面直接拿可能因为数据还没拿到页面就报错了
          this.username = uData[i].userName;
          this.idCord = uData[i].idCard;
          this.phone = uData[i].phone;
          this.uid=uData[i].uid;
            }
          }
         
        })
        .catch((err) => {
          console.log(err);
          Toast("查询失败，请稍后重试！");
          this.$router
            .replace({
              path: "/healcode",
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    //提交
    onSubmit() {
      // console.log("submit", values);
       let phoneReg=/1[3-9]\d{9}/;
       let idCardReg=/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if(this.username.length<=0){
        Toast.fail("姓名不能为空！");
      } else if(this.idCord.length<=0){
                  Toast.fail('身份证号不能为空');
                       return;
              }else if(!idCardReg.test(this.idCord)){
                  Toast.fail('身份证号格式错误');
              }
              else if(!this.phone){
                
                 Toast.fail('电话号码不能为');
                       return;
              }else if(!phoneReg.test(this.phone)){
                         Toast.fail('电话格式错误');
              }
              // else if(!this.familyAddress){
              //   Toast.fail('家庭地址不能为空！');
              // }
        // let sex = this.idcard.substring(16, 17);
        // sex = sex % 2 > 0 ? "男" : "女";
        ajax
          .post("/Api/HealthCardService/GetHealthCardByQRCode", {
            qrCodeText: this.qrCodeText,
          })
          .then((res) => {
            let info = res.data.result.rsp.card;
            let a = {
              birthday: this.birthday,
              idType: "01",
              gender: this.Sex,
              idNumber: this.idCord,
              name: this.username,
              nation: info.nation,
              phone1: this.phone,
              wechatCode: this.wechatCode,
            };
            ajax
              .post("/Api/HealthCardService/RegisterHealthCard", a)
              .then((res) => {
                if (res.data.result.commonOut.errMsg == "成功") {
                  let sex=this.Sex=="男"?true:false;
                  ajax
                    .post("/Api/CardService/UpdateHisUser", {
                      uid: this.uid,
                      idCard: this.idCord,
                      name: this.username,
                      sex: sex,
                      phone: this.phone,
                      machineName: "wechat",
                    })
                    .then((res) => {
                      console.log(res);
                          
                      
                        store.commit("store/setIdcard", this.idCord);
                
                      Toast("修改成功");
                      // this.onload();
                        this.$router.replace("/").catch((err)=>{console.log(err)})
                    })
                    .catch((err) => {
                      console.log(err);
                      Toast("修改失败，请稍后重试！");
                    });
                } else {
                  Toast(res.data.result.commonOut.errMsg);
                }
              })
              .catch((err) => {
                console.log(err);
                Toast("健康卡接口调用失败，请稍后重试！");
              });
          });
    },
    // 修改后调用健康卡注册
    inputHealCard() {},
  },
  watch:{
    idCord(c){
      if(c.length==18){
         let year = c.substring(6, 10);
      let month = c.substring(10, 12);
      let day = c.substring(12, 14);
      let sex = c.substring(16, 17);
      this.Sex = sex % 2 > 0 ? "男" : "女";
      this.Birthday = year + "-" + month + "-" + day;
      // this.Birthdays=year + "/" + month + "/" + day;
      // console.log(this.Sex,this.Birthday)
      }
      // console.log(c)
    }
  }
};
</script>

<style>
</style>
